import { FC, ReactNode } from "react";
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import type { UrlObject } from "url";

import { ColorAA } from "@bagel-web/components";

const NavIcon = styled.i`
  color: ${ColorAA.yellow};
  padding-right: 10px;
`;

const NavItem = styled(Nav.Item)<{ $active: boolean }>`
  ${({ $active }) =>
    $active
      ? `background-color: rgb(var(--aa-active-nav-rgb), var(--aa-active-nav-opacity))`
      : ``}
`;

export type NavItem = {
  iconClassName: string;
  href: string;
  label: ReactNode;
};

export type LinkComponentType = FC<{
  children: ReactNode;
  href: string | UrlObject;
  className?: string | undefined;
}>;

function Sidebar({
  config,
  LinkComponent,
  getIsActive,
}: {
  config: NavItem[];
  LinkComponent: LinkComponentType;
  getIsActive: (navItem: NavItem) => boolean;
}) {
  return (
    <div className="flex-grow bg-secondary" style={{ minWidth: "180px" }}>
      <Nav className="flex-column">
        {config.map((navItem) => {
          const { href, iconClassName, label } = navItem;
          return (
            <NavItem key={href} $active={getIsActive(navItem)}>
              <LinkComponent href={href} className="nav-link">
                <NavIcon className={iconClassName} />
                {label}
              </LinkComponent>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
}

export default Sidebar;
