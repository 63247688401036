import { Alert, Button, Form, Spinner } from "react-bootstrap";
import {
  BagelForkEventStreamCallback,
  BagelGameEventMessage,
  CommonEventMessageType,
  CommonMomentDetail,
  CommonSessionDetails,
  FnInfo,
} from "../types";
import ShowFunctions from "./ShowFunctions";
import { useState } from "react";
import {
  GridFormFlexItemWrapper,
  GridFormItemWrapper,
  IconButton,
  Toolbar,
} from "@bagel-web/components";
import { EventBody, EventContent, FunctionCall } from "./EventStream";
import {
  ScrollingTextArea,
  StyledCard,
  StyledCardBody,
} from "./MomentDetailView";

type TempGameEventMessage = BagelGameEventMessage & { tempId?: string };

const textAreaDefaultStyle = {
  height: "400px",
};

const GameEventCard = ({
  gameEventMessage,
  onDelete,
  onGameEventMessageChange,
}: {
  gameEventMessage: TempGameEventMessage;
  onDelete: () => void;
  onGameEventMessageChange: (message: TempGameEventMessage) => void;
}) => {
  return (
    <StyledCard>
      <StyledCardBody>
        <Toolbar>
          <h6>{gameEventMessage.message_type}</h6>
          <IconButton className="bi-trash" onClick={onDelete} />
        </Toolbar>
        {gameEventMessage.message_type === "FactEventMessage" && (
          <GridFormFlexItemWrapper colSpan={2}>
            <Form.Label className="mb-3">Fact name</Form.Label>
            <EventBody>
              <Form.Control
                required
                value={gameEventMessage.kwargs.fact_name}
                onChange={(e) =>
                  onGameEventMessageChange({
                    ...gameEventMessage,
                    kwargs: {
                      ...gameEventMessage.kwargs,
                      fact_name: e.target.value,
                    },
                  })
                }
              />
            </EventBody>
          </GridFormFlexItemWrapper>
        )}
        {gameEventMessage.message_type === "ImportantContentEventMessage" && (
          <GridFormFlexItemWrapper colSpan={2}>
            <Form.Label className="mb-3">Importance</Form.Label>
            <EventBody>
              <Form.Control
                style={{ width: "80px" }}
                required
                type="number"
                min="-1"
                max="1"
                value={gameEventMessage.kwargs.importance}
                onChange={(e) =>
                  onGameEventMessageChange({
                    ...gameEventMessage,
                    kwargs: {
                      ...gameEventMessage.kwargs,
                      importance: e.target.value,
                    },
                  })
                }
              />
            </EventBody>
          </GridFormFlexItemWrapper>
        )}
        <GridFormItemWrapper colSpan={2}>
          {[
            "ContentEventMessage",
            "ImportantContentEventMessage",
            "FactEventMessage",
          ].includes(gameEventMessage.message_type) ? (
            <EventBody>
              <Form.Control
                style={{ height: "100px" }}
                as="textarea"
                value={gameEventMessage.kwargs.content}
                onChange={(e) =>
                  onGameEventMessageChange({
                    ...gameEventMessage,
                    kwargs: {
                      ...gameEventMessage.kwargs,
                      content: e.target.value,
                    },
                  })
                }
              />
            </EventBody>
          ) : (
            <ScrollingTextArea>
              <EventBody>
                <EventContent>
                  {JSON.stringify(
                    { ...gameEventMessage.kwargs, sender: undefined },
                    null,
                    2
                  )}
                </EventContent>
              </EventBody>
            </ScrollingTextArea>
          )}
        </GridFormItemWrapper>
      </StyledCardBody>
    </StyledCard>
  );
};

const MomentDetailPlayground = ({
  momentDetail,
  sessionDetail,
  forkEventStream,
}: {
  momentDetail: CommonMomentDetail;
  sessionDetail: CommonSessionDetails;
  forkEventStream: BagelForkEventStreamCallback;
}) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [replayResult, setReplayResult] = useState<FnInfo | null>();
  const [error, setError] = useState<string | null>();

  const [apiKey, setApiKey] = useState<string>("");
  const [bios, setBios] = useState<string>(
    momentDetail.slate?.bios?.trim() || ""
  );
  const [characterization, setCharacterization] = useState<string>(
    momentDetail.slate?.characterization?.trim() || ""
  );
  const [cue, setCue] = useState<string>(momentDetail.cue?.trim() || "");
  const [newEventMessageType, setNewEventMessageType] =
    useState<CommonEventMessageType>("ContentEventMessage");
  const [gameEventMessages, setGameEventMessages] = useState<
    TempGameEventMessage[]
  >(
    momentDetail.events
      .filter((event) => event.message.type !== "FnCallEventMessage")
      .map((event) => {
        return {
          tempId: event.id,
          source: event.message.source,
          message_type: event.message.type,
          kwargs: event.message.kwargs,
        };
      })
  );

  const handleGameEventAdd = () => {
    setGameEventMessages([
      ...gameEventMessages,
      {
        tempId: crypto.randomUUID(),
        source: "user",
        message_type: newEventMessageType,
        kwargs: {
          fact_name:
            newEventMessageType === "FactEventMessage" ? "" : undefined,
          content: "",
        },
      },
    ]);
  };

  const handleGameEventDelete = (gameEventId: string | undefined) => {
    setGameEventMessages(
      gameEventMessages.filter(
        (gameEventMessage) => gameEventMessage.tempId !== gameEventId
      )
    );
  };

  const handleGameEventMessageChange = (
    gameEventId: string | undefined,
    updatedGameEventMessage: TempGameEventMessage
  ) => {
    const updatedGameEventMessages = [...gameEventMessages];
    const index = updatedGameEventMessages.findIndex(
      (gameEvent: TempGameEventMessage) => gameEvent.tempId === gameEventId
    );
    updatedGameEventMessages[index] = updatedGameEventMessage;
    setGameEventMessages(updatedGameEventMessages);
  };

  const handleClickReplay = async () => {
    setIsPending(true);
    setReplayResult(null);
    setError(null);

    const newHistory = gameEventMessages.map((gameEventMessage) => {
      const messageForAPI = { ...gameEventMessage };
      delete messageForAPI.tempId;
      return messageForAPI;
    });

    if (
      characterization.trim() !== momentDetail.slate?.characterization?.trim()
    ) {
      newHistory.push({
        source: "user",
        message_type: "SetCharacterizationMessage",
        kwargs: {
          characterization,
        },
      });
    }

    if (bios.trim() !== momentDetail.slate?.bios?.trim()) {
      newHistory.push({
        source: "user",
        message_type: "SetBiosMessage",
        kwargs: {
          bios,
        },
      });
    }

    try {
      const result = await forkEventStream({
        apiKey: apiKey,
        momentId: momentDetail.id,
        functions: momentDetail.functions || [],
        newHistory: newHistory,
        cue: cue !== "" ? cue : undefined,
        projectId: sessionDetail.project_id,
      });

      setReplayResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Error while rerunning moment");
      }
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Form>
      <GridFormFlexItemWrapper style={{ width: "100%" }} className="mb-3">
        <Form.Label>API key</Form.Label>
        <Form.Control
          value={apiKey}
          type="password"
          onChange={(e) => setApiKey(e.target.value)}
        />
      </GridFormFlexItemWrapper>
      <h5>Result</h5>
      <StyledCard>
        <StyledCardBody>
          {isPending && <Spinner />}
          {error && <Alert>{error}</Alert>}
          {!isPending &&
            !error &&
            (replayResult ? (
              <FunctionCall
                fn={{
                  name: replayResult.selected_fn.name,
                  args: replayResult.selected_fn.args,
                }}
              />
            ) : (
              <i>Click Replay to rerun this moment.</i>
            ))}
        </StyledCardBody>
      </StyledCard>
      <Toolbar $justifyContent="flex-end">
        <Button style={{ float: "right" }} onClick={handleClickReplay}>
          Replay
        </Button>
      </Toolbar>
      <h5>Game events</h5>
      {gameEventMessages.map((gameEventMessage) => {
        return (
          <GameEventCard
            key={gameEventMessage.tempId}
            gameEventMessage={gameEventMessage}
            onDelete={() => handleGameEventDelete(gameEventMessage?.tempId)}
            onGameEventMessageChange={(updatedGameEventMessage) =>
              handleGameEventMessageChange(
                gameEventMessage?.tempId,
                updatedGameEventMessage
              )
            }
          />
        );
      })}
      <GridFormFlexItemWrapper style={{ width: "100%" }} className="mb-3">
        <Form.Label>Add game event</Form.Label>
        <Form.Select
          value={newEventMessageType}
          onChange={(e) =>
            setNewEventMessageType(
              e.target.value as
                | "ContentEventMessage"
                | "ImportantContentEventMessage"
                | "FactEventMessage"
            )
          }
        >
          <option value={"ContentEventMessage"}>ContentEventMessage</option>
          <option value={"ImportantContentEventMessage"}>
            ImportantContentEventMessage
          </option>
          <option value={"FactEventMessage"}>FactEventMessage</option>
        </Form.Select>
        <Button style={{ float: "right" }} onClick={handleGameEventAdd}>
          +
        </Button>
      </GridFormFlexItemWrapper>
      <h5>Agent details</h5>
      <StyledCard>
        <StyledCardBody>
          <h6>Bios</h6>
          <Form.Control
            style={textAreaDefaultStyle}
            as="textarea"
            value={bios}
            onChange={(e) => setBios(e.target.value)}
          />
        </StyledCardBody>
      </StyledCard>
      <StyledCard>
        <StyledCardBody>
          <h6>Characterization</h6>
          <Form.Control
            style={textAreaDefaultStyle}
            as="textarea"
            value={characterization}
            onChange={(e) => setCharacterization(e.target.value)}
          />
        </StyledCardBody>
      </StyledCard>

      <StyledCard>
        <StyledCardBody>
          <h6>Cue</h6>
          <Form.Control
            style={{ height: "100px" }}
            as="textarea"
            value={cue}
            onChange={(e) => setCue(e.target.value)}
          />
        </StyledCardBody>
      </StyledCard>
      <StyledCard>
        <StyledCardBody>
          <h6>Functions</h6>
          <ScrollingTextArea>
            {momentDetail.functions && (
              <ShowFunctions functions={momentDetail.functions} />
            )}
          </ScrollingTextArea>
        </StyledCardBody>
      </StyledCard>
    </Form>
  );
};

export default MomentDetailPlayground;
