import { Button, Spinner } from "react-bootstrap";
import styled from "styled-components";

import { Field } from "./types";
import { ReactNode, useEffect, useState } from "react";

const FilterValue = styled.span`
  font-weight: lighter;
`;

const FilterChip = ({
  field,
  value,
  onClick,
}: {
  field: Field;
  value?: string;
  onClick: () => void;
}) => {
  const [formattedValue, setFormattedValue] = useState<ReactNode>(null);

  useEffect(() => {
    const fetchFormattedValue = async () => {
      if (!field.formatValue) {
        setFormattedValue(value);
        return;
      }
      setFormattedValue(await field.formatValue(value));
    };
    fetchFormattedValue();
  }, [field.formatValue, value]);

  return (
    <Button onClick={onClick} variant="light">
      {`${field.label}: `}
      {formattedValue ? (
        <FilterValue className="font-weight-light">
          {formattedValue}
        </FilterValue>
      ) : (
        <Spinner size="sm" />
      )}
      <i className="bi-x" />
    </Button>
  );
};

export default FilterChip;
