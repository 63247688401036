import { AttributesView, toAttributes } from "@bagel-web/components";
import styled from "styled-components";

import { CommonAgentSummary, AgentConfig } from "../types";
import { formatTimestamp } from "../util";

const AgentName = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
`;

const MetadataLabel = styled.div`
  margin: 4px 0px;
  font-weight: bold;
`;


type NamedConfig = {
  name: string;
  config: Record<string, any>;
};


const AgentDetails = ({ agent }: { agent: CommonAgentSummary | null }) => {
  const agentName = agent?.metadata["name"];
  const agentConfig: AgentConfig | undefined = agent?.config;

  return (
    <div>
      <AgentName>{agentName || <code>{agent?.id}</code>}</AgentName>
      <>
        {agentConfig?.name && (
          <AttributesView
            attributes={[
              {
                name: "ash",
                label: "ASH",
                value: agentConfig?.name,
              },
              {
                  name: "created",
                  label: "created",
                  value: formatTimestamp(agentConfig?.created),
              },
              {
                  name: "destroyed",
                  label: "destroyed",
                  value: formatTimestamp(agentConfig?.destroyed),
              },
              {
                name: "llm_name",
                label: agentConfig?.config?.agent_llm ? "Agent LLM" : "LLM",
                value: (
                  agentConfig?.config?.agent_llm ?
                    agentConfig?.config?.agent_llm :
                    agentConfig?.config?.llm_config?.name
                ),
              },
              {
                name: "temperature",
                label: "Temperature",
                value: agentConfig?.config?.llm_config?.config?.temperature,
              },
            ]}
          />
        )}
        {Object.entries(agentConfig?.config?.component_configs || {}).length > 0 && (
          <>
            <MetadataLabel>Components</MetadataLabel>
            <AttributesView
              attributes={
                toAttributes(
                  Object.entries(agentConfig.config.component_configs).reduce(
                    (acc, [k, v]) => {
                      const nc = v as NamedConfig;
                      acc[k] = `${nc.name}${nc.config.use_service ? ` (${nc.config.use_service})` : ''}`;
                      return acc;
                    },
                    {} as Record<string, string>
                  )
                )
              }
            />
          </>
        )}
        {Object.entries(agentConfig?.config?.service_configs || {}).length > 0 && (
          <>
            <MetadataLabel>Services</MetadataLabel>
            <AttributesView
              attributes={
                toAttributes(
                  Object.entries(agentConfig.config.service_configs).reduce(
                    (acc, [k, v]) => {
                      const nc = v as NamedConfig;
                      acc[k] = `${nc.name}`;
                      return acc;
                    },
                    {} as Record<string, string>
                  )
                )
              }
            />
          </>
        )}
        {Object.entries(agent?.metadata || {}).length > 0 && (
          <>
            <MetadataLabel>Metadata</MetadataLabel>
            <AttributesView attributes={toAttributes(agent?.metadata)} />
          </>
        )}
      </>
    </div>
  );
};

export default AgentDetails;
