import { LinePlot, useModelColorMap } from "@bagel-web/components";
import { Usage } from "../types";
import { Form } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import { formatCurrency, formatNumber } from "../util";

const UsageDetailReport = ({
  usage,
  showTokenCounts = false,
  getCost,
}: {
  usage: Usage[];
  showTokenCounts?: boolean;
  getCost?: (usage: Usage) => number;
}) => {
  const { getColor } = useModelColorMap();
  const [selectedField, setSelectedField] = useState<string>("requests");

  const allModels = Array.from(
    new Set([...(usage?.map((u) => u.model) || [])])
  );

  const datasets = allModels.map((modelName) => {
    return {
      label: modelName,
      borderColor: getColor(modelName),
      backgroundColor: getColor(modelName),
      data: usage
        .filter((u) => u.model === modelName)
        .map((u) => {
          if (selectedField === "cost" && getCost) {
            return { x: u.timestamp, y: getCost(u) };
          } else {
            return { x: u.timestamp, y: u[selectedField as keyof Usage] };
          }
        }),
    };
  });

  const handleChangeSelectedField = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedField(e.target.value);
  };

  const options: { [key: string]: object } = {};

  if (selectedField === "cost") {
    options.scales = {
      y: {
        ticks: {
          callback: (value: string | number) => {
            return Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(value as number);
          },
        },
      },
    };
  }

  return (
    <div>
      {showTokenCounts && (
        <Form.Select
          name="usage-detail-field-selector"
          className="mt-3 mb-3"
          value={selectedField}
          onChange={handleChangeSelectedField}
        >
          <option value={"requests"}>Requests</option>
          <option value={"input_token"}>Input tokens</option>
          <option value={"output_tokens"}>Output tokens</option>
          <option value={"cost"}>Estimated cost</option>
        </Form.Select>
      )}
      <LinePlot
        data={{
          datasets: datasets,
        }}
        formatValue={
          selectedField === "cost"
            ? (value) => {
                return formatCurrency(value as number);
              }
            : (value) => {
                return formatNumber(value as number);
              }
        }
      />
    </div>
  );
};

export default UsageDetailReport;
