import {
  AttributesView,
  BreakdownBar,
  useModelColorMap,
} from "@bagel-web/components";

import type { Usage } from "../types";
import { BarReportSectionContainer, BarReportSectionHeader } from "./BarReport";
import { formatCurrency, formatNumber } from "../util";

const UsageTooltip = ({
  usage,
  usageKey,
  getCost,
}: {
  usage: Usage;
  usageKey?: string;
  getCost?: (usage: Usage[] | Usage) => number;
}) => {
  const attributes = [
    {
      name: "model",
      label: "Model",
      value: `${usage.model}`,
      showMonoValue: true,
      showBold: usageKey === "model",
    },
  ];

  if (usageKey === "cost" && getCost) {
    attributes.push({
      name: "cost",
      label: "Cost (USD)",
      value: `${formatCurrency(getCost(usage))}`,
      showMonoValue: false,
      showBold: true,
    });
  } else {
    attributes.push({
      name: "requests",
      label: "Requests",
      value: `${formatNumber(usage.requests)}`,
      showMonoValue: false,
      showBold: usageKey === "requests",
    });

    if (usage.input_token) {
      attributes.push({
        name: "input_tokens",
        label: "Input tokens",
        value: `${formatNumber(usage.input_token)}`,
        showMonoValue: false,
        showBold: usageKey === "input_token",
      });
    }

    if (usage.output_tokens) {
      attributes.push({
        name: "output_tokens",
        label: "Output tokens",
        value: `${formatNumber(usage.output_tokens)}`,
        showMonoValue: false,
        showBold: usageKey === "output_tokens",
      });
    }
  }

  return <AttributesView attributes={attributes} />;
};

const UsageSummaryBar = ({
  usage,
  usageKey,
  getCost,
}: {
  usage?: Usage[];
  usageKey: string;
  getCost?: (usage: Usage[] | Usage) => number;
}) => {
  const { getColor } = useModelColorMap();
  return (
    usage && (
      <BreakdownBar
        segments={usage?.map((u) => {
          return {
            size:
              usageKey === "cost" && getCost
                ? getCost(u)
                : Number(u[usageKey as keyof Usage]),
            tooltip: (
              <UsageTooltip usage={u} usageKey={usageKey} getCost={getCost} />
            ),
            color: getColor(u.model),
          };
        })}
      />
    )
  );
};

const sortUsage = (
  usageKey: string,
  usage?: Usage[],
  getCost?: (usage: Usage[] | Usage) => number
): Usage[] | undefined => {
  return usage?.sort((l, r) => {
    if (usageKey === "cost" && getCost) {
      return getCost(r) - getCost(l);
    } else {
      return (
        Number(r[usageKey as keyof Usage]) - Number(l[usageKey as keyof Usage])
      );
    }
  });
};

const getTotalUsage = (
  usageKey: string,
  usage?: Usage[],
  getCost?: (usage: Usage[]) => number
): number => {
  if (usageKey === "cost" && getCost) {
    return (usage && getCost(usage)) || 0;
  } else {
    return (
      usage?.reduce((sum, u) => sum + Number(u[usageKey as keyof Usage]), 0) ||
      0
    );
  }
};

const getFieldLabel = (usageKey: string, total: number) => {
  const plural = total !== 1;
  if (usageKey === "requests") {
    return plural ? "requests" : "request";
  } else if (usageKey === "input_token") {
    return plural ? "input tokens" : "input token";
  } else if (usageKey === "output_tokens") {
    return plural ? "output tokens" : "output token";
  } else if (usageKey === "cost") {
    return "";
  }
};

const getTotalLabel = (
  showFieldLabel: boolean,
  usageKey: string,
  usage?: Usage[],
  getCost?: (usage: Usage[]) => number
): string => {
  const totalUsage = getTotalUsage(usageKey, usage, getCost);

  if (usageKey === "cost") {
    return formatCurrency(totalUsage);
  }
  return `${formatNumber(totalUsage)} ${
    showFieldLabel ? getFieldLabel(usageKey, totalUsage) : ""
  }`;
};

const SummarySection = ({
  usage,
  title,
  scope,
  timestamp,
  showFieldLabel = false,
  usageKey = "requests",
  getCost,
}: {
  usage?: Usage[];
  title: string;
  scope: string;
  timestamp?: string;
  showFieldLabel?: boolean;
  usageKey?: string;
  getCost?: (usage: Usage[] | Usage) => number;
}) => {
  const filteredUsage = usage?.filter(
    (u) => u.scope === scope && (!timestamp || u.timestamp === timestamp)
  );
  sortUsage(usageKey, filteredUsage, getCost);
  return (
    <BarReportSectionContainer>
      <BarReportSectionHeader>
        <h6>{title}</h6>
        <h6>
          {getTotalLabel(showFieldLabel, usageKey, filteredUsage, getCost)}
        </h6>
      </BarReportSectionHeader>
      <UsageSummaryBar
        usage={filteredUsage}
        usageKey={usageKey}
        getCost={getCost}
      />
    </BarReportSectionContainer>
  );
};

type ReportSection = {
  title: string;
  scope: string;
  timestamp?: string;
};

const UsageSummaryReport = ({
  usage,
  sections,
  showFieldLabel = false,
  summaryField = "requests",
  getCost,
}: {
  usage?: Usage[];
  sections?: ReportSection[];
  showFieldLabel?: boolean;
  summaryField?: string;
  getCost?: (usage: Usage[] | Usage) => number;
}) => {
  return (
    <>
      {sections ? (
        sections.map(({ scope, timestamp, title }) => {
          return (
            <SummarySection
              key={`usage-${scope}-${timestamp}`}
              usage={usage}
              title={title}
              scope={scope}
              timestamp={timestamp}
              showFieldLabel={showFieldLabel}
              usageKey={summaryField}
              getCost={getCost}
            />
          );
        })
      ) : (
        <>
          <SummarySection
            usage={usage}
            title="Today"
            scope="daily"
            showFieldLabel={showFieldLabel}
            usageKey={summaryField}
            getCost={getCost}
          />
          <SummarySection
            usage={usage}
            title="This month"
            scope="monthly"
            showFieldLabel={showFieldLabel}
            usageKey={summaryField}
            getCost={getCost}
          />
        </>
      )}
    </>
  );
};

export default UsageSummaryReport;
