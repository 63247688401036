import { ReactNode, useState } from "react";

import styled from "styled-components";

import { ColorAA } from "./constants";

const PageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

const PageHeaderContainer = styled.div`
  flex: 0;
  border-bottom: 1px solid ${ColorAA.lightGrey};
  position: relative;
  padding: 20px;
`;

const PageBody = styled.div`
  flex: 1;
  flex-basis: 100%;
  min-height: 0;
  overflow: auto;
  padding: 20px;
`;

const StyledButton = styled.a`
  margin: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Page = ({ children }: { children?: ReactNode }) => {
  return <PageContainer>{children}</PageContainer>;
};

const PageHeaderToggle = ({
  onToggle,
  collapsed,
}: {
  onToggle: () => void;
  collapsed?: boolean;
}) => {
  return (
    <StyledButton onClick={onToggle}>
      {collapsed ? <>Show more</> : <>Show less</>}
    </StyledButton>
  );
};

const PageHeader = ({
  children,
  collapsible,
  defaultCollapsed,
  className,
}: {
  children?: ReactNode | (({ collapsed }: { collapsed: boolean }) => ReactNode);
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  className?: string;
}) => {
  const [collapsed, setCollapsed] = useState(
    Boolean(defaultCollapsed !== undefined && defaultCollapsed)
  );

  return (
    <PageHeaderContainer className={className}>
      {typeof children === "function" ? children({ collapsed }) : children}
      {collapsible && (
        <PageHeaderToggle
          collapsed={collapsed}
          onToggle={() => {
            setCollapsed(!collapsed);
          }}
        />
      )}
    </PageHeaderContainer>
  );
};

export default Object.assign(Page, {
  Header: PageHeader,
  Body: PageBody,
});
