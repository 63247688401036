import styled from "styled-components";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import IconButton from "./IconButton";

const CopyValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CopyValue = ({ children }: { children: string }) => {
  const copyTooltip = <Tooltip style={{ position: "fixed" }}>Copied</Tooltip>;

  const copyValue = async () => {
    navigator.clipboard.writeText(children);
  };

  return (
    <CopyValueContainer>
      <code>{children}</code>
      <OverlayTrigger overlay={copyTooltip} trigger="click" rootClose>
        <IconButton className={"bi-copy"} onClick={copyValue} />
      </OverlayTrigger>
    </CopyValueContainer>
  );
};

export default CopyValue;
