import { RequestUsageBar, useModelColorMap } from "@bagel-web/components";

import type { Usage } from "../types";
import { RequestLimits } from "../types/Limits";
import { BarReportSectionContainer, BarReportSectionHeader } from "./BarReport";

const ReportSection = ({
  title,
  requestCount = 0,
  requestLimit,
  color,
}: {
  title: string;
  requestCount?: number;
  requestLimit?: number;
  color?: string;
}) => {
  return (
    <BarReportSectionContainer>
      <BarReportSectionHeader>
        <h6>
          <code>{title}</code>
        </h6>
        <h6>
          {requestLimit ? (
            <>
              <b>Request limit: </b>
              {Intl.NumberFormat().format(requestLimit)}
            </>
          ) : (
            <>No request limit</>
          )}
        </h6>
      </BarReportSectionHeader>
      <RequestUsageBar
        used={requestCount}
        max={requestLimit || Infinity}
        color={color}
      />
    </BarReportSectionContainer>
  );
};

const UsageAndLimitsReport = ({
  usage = [],
  limits = {},
}: {
  usage?: Usage[];
  limits?: RequestLimits;
}) => {
  const allModels = Array.from(
    new Set([...(usage?.map((u) => u.model) || []), ...Object.keys(limits)])
  );
  const { getColor } = useModelColorMap();

  return (
    <>
      {allModels.map((model) => (
        <ReportSection
          key={model}
          title={model}
          requestCount={
            usage.find((u) => u.model === model && u.scope === "daily")
              ?.requests
          }
          requestLimit={limits[model]}
          color={getColor(model)}
        />
      ))}
    </>
  );
};

export default UsageAndLimitsReport;
