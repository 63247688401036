import { Badge } from "react-bootstrap";

type Environment = {
  name: string;
  pathFragment: string;
  label?: string;
};

const ENVIRONMENTS: Environment[] = [
  {
    name: "prod",
    pathFragment: "prod",
    label: "",
  },
  {
    name: "local",
    pathFragment: "localhost",
    label: "Local",
  },
  {
    name: "sandbox",
    pathFragment: "sandbox",
    label: "Sandbox",
  },
  {
    name: "dev",
    pathFragment: "dev",
    label: "Dev",
  },
];

const getEnvironment = (): Environment | undefined => {
  // TODO: Environment source of truth should be an API rather than path
  return ENVIRONMENTS.find(({ pathFragment }) =>
    window.location.hostname.includes(pathFragment)
  );
};

const EnvironmentBadge = () => {
  const env = getEnvironment();
  return env && env.name !== "prod" && <Badge bg="warning">{env.label}</Badge>;
};

export default EnvironmentBadge;
