import type {
  Limits,
  Usage,
  CommonMomentDetail,
  FnDesc,
  BagelGameEventMessage,
  Page,
} from "@bagel-web/common";

import type {
  APIKey,
  APIRequest,
  APIRequestSummary,
  FnInfo,
  Organization,
  Project,
  SessionDetails,
  SessionSummary,
  User,
} from "./types";

type ErrorDetails = {
  type: string;
  message: string;
  trace: string;
};

export class APIError extends Error {
  error: ErrorDetails;

  constructor({ error }: { error: ErrorDetails }) {
    super(error.message);
    this.name = "APIError";
    this.error = error;
  }
}

function buildQueryString(
  obj: Record<string, string | number | boolean | undefined>
) {
  return Object.keys(obj)
    .filter((k) => !!obj[k])
    .map((k) => {
      const v = obj[k];
      const encodedValue =
        v !== null && v !== undefined ? encodeURIComponent(v) : "";
      return `${encodeURIComponent(k)}=${encodedValue}`;
    })
    .join("&");
}

async function parseError(res: Response): Promise<Error> {
  try {
    const json = await res.json();

    if ("error" in json) {
      return new APIError(json);
    }
  } catch (e) {
    // fall back to a generic error.
  }

  return new Error(`Unexpected response status ${res.status}`);
}

export async function getAPI<T>(url: string): Promise<T> {
  const res = await fetch(url);

  if (res.status === 200) return res.json();
  else throw await parseError(res);
}

export async function postAPI<T>(
  url: string,
  body: object,
  headers?: object
): Promise<T> {
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json", ...headers },
    body: JSON.stringify(body),
  });

  if (res.status === 200) return res.json();
  else throw await parseError(res);
}

export async function fetchOrganization(): Promise<Organization> {
  return getAPI("/api/organization");
}

export async function fetchProjects(): Promise<Page<Project>> {
  return getAPI("/api/projects");
}

export async function fetchUsers(): Promise<Page<User>> {
  return getAPI("/api/users");
}

export async function fetchAPIKeys({
  startsAfter = null,
  notes = null,
}: {
  startsAfter: string | null;
  notes: string | null;
}): Promise<Page<APIKey>> {
  return getAPI(
    "/api/api-keys?" +
      buildQueryString({
        starting_after: startsAfter || undefined,
        notes: notes || undefined,
      })
  );
}

export async function fetchAPIRequests({
  startsAfter = null,
  showResults = "all",
  projectId = null,
  agentId,
  sessionId,
}: {
  startsAfter: string | null;
  showResults: "all" | "only-errors";
  projectId: string | null;
  agentId?: string;
  sessionId?: string;
}): Promise<Page<APIRequestSummary>> {
  const onlyFailed = showResults === "only-errors";

  return getAPI(
    "/api/api-requests?" +
      buildQueryString({
        starting_after: startsAfter || undefined,
        only_failed: onlyFailed,
        project_id: projectId || undefined,
        agent_id: agentId,
        session_id: sessionId,
      })
  );
}

export async function fetchAPIRequest(id: string): Promise<APIRequest> {
  return getAPI(`/api/api-requests/${id}`);
}

export async function fetchSessions({
  startsAfter = null,
  projectId = null,
  sessionId = null,
  metadata = null,
  apiKeyId = null,
  fromDateTime = null,
  toDateTime = null,
}: {
  startsAfter: string | null;
  projectId: string | null;
  sessionId: string | null;
  metadata: string | null;
  apiKeyId: string | null;
  fromDateTime: number | null;
  toDateTime: number | null;
}): Promise<Page<SessionSummary>> {
  return getAPI(
    "/api/sessions?" +
      buildQueryString({
        starting_after: startsAfter || undefined,
        project_id: projectId || undefined,
        id: sessionId || undefined,
        metadata: metadata || undefined,
        api_key_id: apiKeyId || undefined,
        from_date_time: fromDateTime || undefined,
        to_date_time: toDateTime || undefined,
      })
  );
}

export function fetchSession(sessionId: string): Promise<SessionDetails> {
  return getAPI(`/api/sessions/${sessionId}`);
}

export function fetchOrganizationUsageRecent(): Promise<Usage[]> {
  return getAPI("/api/organization/usage");
}

export function fetchOrganizationUsage(beginsWith: string): Promise<Usage[]> {
  return getAPI(
    "/api/organization/usage?" +
      buildQueryString({
        begins_with: beginsWith,
      })
  );
}

export async function fetchOrganizationUsageBetween(
  startDate: string,
  endDate: string
): Promise<Usage[]> {
  return getAPI(
    `/api/organization/usage?${buildQueryString({
      between: `daily-${startDate},daily-${endDate}`,
    })}`
  );
}

export function fetchOrganizationLimits(): Promise<Limits> {
  return getAPI("/api/organization/limits");
}

export function fetchEventStreamDetail(
  sessionId: string,
  agentId: string,
  momentId: number
): Promise<CommonMomentDetail> {
  return getAPI(
    `/api/sessions/${sessionId}/agent/${agentId}/event-stream/${momentId}`
  );
}

export function forkMoment({
  apiKey,
  sessionId,
  agentId,
  momentId,
  projectId,
  metadata,
  functions,
  newHistory,
  cue,
}: {
  apiKey: string;
  sessionId: string;
  agentId: string;
  momentId: number;
  projectId?: string;
  metadata?: Record<string, string>;
  functions: FnDesc[];
  newHistory: BagelGameEventMessage[];
  cue?: string;
}) {
  return postAPI<FnInfo>(
    `/api/sessions/${sessionId}/agent/${agentId}/event-stream/${momentId}/fork`,
    {
      api_key: apiKey,
      project_id: projectId,
      metadata,
      new_history: newHistory,
      functions,
      cue,
    }
  );
}
