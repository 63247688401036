import type { NextComponentType } from "next";

import ClientLayout from "./ClientLayout";
import { AppProps } from "next/app";
import Head from "next/head";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import React, { ReactNode, useEffect } from "react";
import { useRouter } from "next/router";

// Nested layouts are not supported by the NextJS Pages router.
// This is a workaround to allow pages to specify a secondary
// layout so that the wrapping layout isn't recreated on navigation.

type SecondaryLayoutComponentType = ({
  children,
}: {
  children: ReactNode;
}) => ReactNode;

type BagelWebComponentType = NextComponentType & {
  SecondaryLayoutComponent: SecondaryLayoutComponentType;
};

type BagelAppProps = AppProps & {
  Component: BagelWebComponentType;
};

const ConditionalSecondaryLayout = ({
  SecondaryLayoutComponent,
  children,
}: {
  SecondaryLayoutComponent?: SecondaryLayoutComponentType;
  children: ReactNode;
}) => {
  return SecondaryLayoutComponent ? (
    <SecondaryLayoutComponent>{children}</SecondaryLayoutComponent>
  ) : (
    children
  );
};

export default function RootLayout({ Component, pageProps }: BagelAppProps) {
  /* 
    This redirect is a hack to work with our static asset hosting.
    The web server directs all page requests to the top-level index.html,
    and we handle redirection to subpages via client-side routing.

    This is suboptimal for page performance, but is challenging to overcome
    with NextJS's current support for dynamic routes with static exports.
  */
  const router = useRouter();
  useEffect(() => {
    if (router.isReady && router.pathname !== "/_error") {
      router.push(router.asPath);
    }
  }, [router.isReady]);

  return (
    <>
      <Head>
        <title>Artificial Agency</title>
      </Head>
      <div id="root">
        <ClientLayout>
          <ConditionalSecondaryLayout
            SecondaryLayoutComponent={Component.SecondaryLayoutComponent}
          >
            <Component {...pageProps} />
          </ConditionalSecondaryLayout>
        </ClientLayout>
      </div>
    </>
  );
}
