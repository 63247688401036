import styled from "styled-components";

export const BarReportSectionContainer = styled.div`
  margin-bottom: 20px;
`;

export const BarReportSectionHeader = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
`;
