import { ReactNode } from "react";

import styled from "styled-components";

type Attribute = {
  name: string;
  label: string;
  value?: ReactNode;
  showMonoLabel?: boolean;
  showMonoValue?: boolean;
  showBold?: boolean;
};

export const toAttributes = (
  metadata?: Record<string, string>
): Attribute[] | null => {
  return metadata
    ? Object.entries(metadata).map(([k, v]) => ({
        name: k,
        label: k,
        value: v,
        showMonoLabel: false,
        showMonoValue: true,
        showBold: false,
      }))
    : null;
};

const AttributesViewContainer = styled.table`
  display: grid;
  width: fit-content;
  grid-template-columns: max-content max-content;
  text-align: left;
`;

const AttributesViewBody = styled.tbody`
  display: contents;
`;

const AttributeRow = styled.tr`
  display: contents;
  padding: 5px;
`;

const AttributeLabel = styled.td`
  font-style: italic;
  padding: 0px 5px;
`;

const AttributeValue = styled.td`
  padding: 0px 5px;
`;

const AttributeText = ({
  showMono,
  showBold,
  children,
}: {
  showMono?: boolean;
  showBold?: boolean;
  children?: ReactNode;
}) => {
  if (showBold) {
    return showMono ? (
      <b>
        <code>{children}</code>
      </b>
    ) : (
      <b>{children}</b>
    );
  } else {
    return showMono ? <code>{children}</code> : children;
  }
};

const Attribute = ({
  attribute: {
    label,
    value,
    showMonoLabel = false,
    showMonoValue = true,
    showBold = false,
  },
}: {
  attribute: Attribute;
}) => {
  return (
    <AttributeRow>
      <AttributeLabel>
        <AttributeText showBold={showBold} showMono={showMonoLabel}>
          {label}
        </AttributeText>
      </AttributeLabel>
      <AttributeValue>
        <AttributeText showBold={showBold} showMono={showMonoValue}>
          {value}
        </AttributeText>
      </AttributeValue>
    </AttributeRow>
  );
};

const StyledAttribute = styled(Attribute)``;

export const AttributesView = ({
  attributes,
}: {
  attributes?: Attribute[] | null;
}) => {
  return (
    <AttributesViewContainer>
      <AttributesViewBody>
        {attributes?.map((attribute) => {
          return <StyledAttribute key={attribute.name} attribute={attribute} />;
        })}
      </AttributesViewBody>
    </AttributesViewContainer>
  );
};
