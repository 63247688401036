import Image from "next/image";
import Link from "next/link";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";

import { ColorAA } from "@bagel-web/components";
import type { WhoAmI } from "types/app";

const AvatarImage = styled(Image)`
  border: 1px solid ${ColorAA.yellow};
`;

const AvatarNoImage = styled.span`
  color: ${ColorAA.yellow};
  font-size: 28px;
  line-height: 28px;
`;

function AvatarPopover({
  whoami,
  onLogOut,
}: {
  whoami: WhoAmI;
  onLogOut: () => void;
}) {
  const popover = (
    <Popover className="avatar-popover">
      <p>{whoami.user.email}</p>

      <Link href="/organization" className="d-block">
        <i className="bi-building" /> My organization
      </Link>

      <Button
        variant="secondary"
        className="d-block mt-3"
        onClick={() => onLogOut()}
      >
        Log out
      </Button>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose={true}
      overlay={popover}
    >
      <div className="avatar">
        {whoami.user.avatar_url ? (
          <AvatarImage
            src={whoami.user.avatar_url}
            alt=""
            width={28}
            height={28}
            unoptimized={true}
            referrerPolicy="no-referrer"
          />
        ) : (
          <AvatarNoImage className="bi-person-circle" />
        )}
      </div>
    </OverlayTrigger>
  );
}

export default AvatarPopover;
