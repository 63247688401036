export function formatCurrency(value: number) {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
}

export function formatNumber(value: number) {
  return Intl.NumberFormat().format(value);
}

export function formatTimestamp(timestamp: number | null | undefined): string {
  if (timestamp == null) return "";

  return formatDate(new Date(timestamp * 1000));
}

export function formatDate(date: Date | null): string {
  if (date === null) return "";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

export function getTimestampFromDateString(dateStr: string): number {
  return new Date(dateStr).getTime() / 1000;
}
