import {
  AgentDetails,
  CommonSessionDetails,
  SessionDetails,
} from "@bagel-web/common";
import { CopyValue, HeaderDetail, Inspector } from "@bagel-web/components";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";

const EventStreamHeaderDetail = ({
  sessionId,
  agentId,
  fetchSession,
}: {
  sessionId: string;
  agentId: string;
  fetchSession: (sessionId: string) => Promise<CommonSessionDetails>;
}) => {
  const [session, setSession] = useState<CommonSessionDetails | null>(null);
  const queryClient = useQueryClient();

  const handlePreviewSession = useCallback(async () => {
    if (session) {
      return;
    }
    const sessionData = await fetchSession(sessionId);
    setSession(sessionData);
  }, [queryClient, setSession, session]);

  return (
    <HeaderDetail>
      <HeaderDetail.Field label="Session">
        <Inspector
          View={SessionDetails}
          viewProps={{ session: session || null }}
          onEnter={handlePreviewSession}
          showLoading={!session}
        >
          <CopyValue>{sessionId}</CopyValue>
        </Inspector>
      </HeaderDetail.Field>
      <HeaderDetail.Field label="Agent">
        <Inspector
          View={AgentDetails}
          viewProps={{
            agent:
              session?.agents?.find((agent) => agent.id === agentId) || null,
          }}
          onEnter={handlePreviewSession}
          showLoading={!session}
        >
          <CopyValue>{agentId}</CopyValue>
        </Inspector>
      </HeaderDetail.Field>
    </HeaderDetail>
  );
};

export default EventStreamHeaderDetail;
