import { Dispatch, useEffect, useReducer, useState } from "react";

import { Pagination } from "react-bootstrap";

import type { Page } from "../types";

export type PaginatedSearch<T> = T & { startsAfter: string | null };

type State<T> = {
  // the "startsAfter" cursor for the page after the current page
  nextPageStartsAfter: string | null;

  // the "startsAfter" cursor for the currently displayed page
  currentPageStartsAfter: string | null;

  // the history of "startsAfter"s that we've viewed
  // maintained so that we can go to the previous page without backend support
  paginationHistory: Array<string | null>;

  // the current search, augmented with a startsAfter parameter
  paginatedSearch: PaginatedSearch<T>;
};

type Action<T> =
  | { type: "got-page"; nextPageStartingAfter: string | null } // we received a new page
  | { type: "go-next" } // go to the next page, pushing the current page on to the history
  | { type: "go-prev" } // go to the previous page, popping the history into the current page
  | { type: "reset" } // go to the first page, clearing the history
  | { type: "new-search"; search: T }; // receive a new search, clearing the history

function paginationReducer<T>(state: State<T>, action: Action<T>): State<T> {
  let currentPageStartsAfter;

  switch (action.type) {
    case "got-page":
      return {
        ...state,
        nextPageStartsAfter: action.nextPageStartingAfter,
      };
    case "go-next":
      currentPageStartsAfter = state.nextPageStartsAfter;

      return {
        ...state,
        paginatedSearch: {
          ...state.paginatedSearch,
          startsAfter: currentPageStartsAfter,
        },
        nextPageStartsAfter: null,
        currentPageStartsAfter: currentPageStartsAfter,
        paginationHistory: [
          ...state.paginationHistory,
          state.currentPageStartsAfter,
        ],
      };
    case "go-prev":
      currentPageStartsAfter =
        state.paginationHistory[state.paginationHistory.length - 1]!;

      return {
        ...state,
        paginatedSearch: {
          ...state.paginatedSearch,
          startsAfter: currentPageStartsAfter,
        },
        nextPageStartsAfter: null,
        currentPageStartsAfter,
        paginationHistory: state.paginationHistory.slice(0, -1),
      };
    case "reset":
      return {
        ...state,
        paginatedSearch: { ...state.paginatedSearch, startsAfter: null },
        nextPageStartsAfter: null,
        currentPageStartsAfter: null,
        paginationHistory: [],
      };
    case "new-search":
      return {
        ...state,
        paginatedSearch: { ...action.search, startsAfter: null },
        nextPageStartsAfter: null,
        currentPageStartsAfter: null,
        paginationHistory: [],
      };
  }
}

export function usePagination<T>(initialSearch: PaginatedSearch<T>): {
  state: State<T>;
  dispatch: Dispatch<Action<T>>;
} {
  const [state, dispatch] = useReducer<React.Reducer<State<T>, Action<T>>>(
    paginationReducer,
    {
      nextPageStartsAfter: null,
      currentPageStartsAfter: null,
      paginationHistory: [],
      paginatedSearch: initialSearch,
    }
  );

  return { state, dispatch };
}

export function Paginator<T>({
  // note that you must use useMemo() or similar to ensure that you're passing the same
  // search object when the search hasn't changed.
  search,
  currentPage,
  onChangePaginatedSearch,
}: {
  search: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentPage: Page<any> | null;
  onChangePaginatedSearch: ({
    paginatedSearch,
    isFirstPage,
  }: {
    paginatedSearch: T & { startsAfter: string | null };
    isFirstPage: boolean;
  }) => void;
}) {
  const { state, dispatch } = usePagination<T>({
    ...search,
    startsAfter: null,
  });

  const isFirstPage = state.currentPageStartsAfter === null;

  const nextPageStartingAfter = currentPage?.next_page_starting_after || null;
  useEffect(() => {
    dispatch({
      type: "got-page",
      nextPageStartingAfter,
    });
  }, [nextPageStartingAfter]);

  useEffect(() => {
    onChangePaginatedSearch({
      paginatedSearch: state.paginatedSearch,
      isFirstPage: isFirstPage,
    });
  }, [state.paginatedSearch, isFirstPage]);

  // reset the pagination state when we get a new search
  const [prevSearch, setPrevSearch] = useState(search);
  if (search !== prevSearch) {
    setPrevSearch(search);
    dispatch({ type: "new-search", search });
  }

  return (
    <Pagination>
      <Pagination.First
        disabled={isFirstPage}
        onClick={() => dispatch({ type: "reset" })}
      />
      <Pagination.Prev
        disabled={state.paginationHistory.length === 0}
        onClick={() => {
          dispatch({ type: "go-prev" });
        }}
      />
      <Pagination.Next
        disabled={!state.nextPageStartsAfter}
        onClick={() => {
          dispatch({ type: "go-next" });
        }}
      />
    </Pagination>
  );
}
