import styled from "styled-components";
import { Card } from "react-bootstrap";
import { CommonMomentDetail } from "../types";
import ShowFunctions from "./ShowFunctions";

export const StyledCard = styled(Card)`
  margin-bottom: 20px;
`;

export const StyledCardBody = styled(Card.Body)`
  white-space: pre-wrap;
`;

export const ScrollingTextArea = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const MomentDetaiLView = ({
  momentDetail,
}: {
  momentDetail: CommonMomentDetail;
}) => {
  return (
    <>
      <StyledCard>
        <StyledCardBody>
          <h6>Bios</h6>
          <ScrollingTextArea>
            {momentDetail.slate?.bios.trim()}
          </ScrollingTextArea>
        </StyledCardBody>
      </StyledCard>
      <StyledCard>
        <StyledCardBody>
          <h6>Characterization</h6>
          <ScrollingTextArea>
            {momentDetail.slate?.characterization.trim()}
          </ScrollingTextArea>
        </StyledCardBody>
      </StyledCard>
      {momentDetail.cue && (
        <StyledCard>
          <StyledCardBody>
            <h6>Cue</h6>
            {momentDetail.cue}
          </StyledCardBody>
        </StyledCard>
      )}
      <StyledCard>
        <StyledCardBody>
          <h6>Functions</h6>
          <ScrollingTextArea>
            {momentDetail.functions && (
              <ShowFunctions functions={momentDetail.functions} />
            )}
          </ScrollingTextArea>
        </StyledCardBody>
      </StyledCard>
    </>
  );
};

export default MomentDetaiLView;
