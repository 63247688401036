import { Chart as ChartJS, CategoryScale, ChartData } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import moment from "moment";
import "chartjs-adapter-moment";

ChartJS.register(CategoryScale);

const LinePlotContainer = styled.div`
  width: 100%;
  height: 300px;
`;

const LinePlot = ({
  data,
  formatValue,
}: {
  data: ChartData<"line", object[], string>;
  formatValue?: (value: string | number) => string;
}) => {
  return (
    <LinePlotContainer>
      <Line
        data={data}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "time",
              time: { unit: "day", tooltipFormat: "MMMM DD, YYYY" },
              suggestedMin: moment().subtract(1, "months").format("YYYY-MM-DD"),
              suggestedMax: moment().format("YYYY-MM-DD"),
            },
            y: {
              ticks: {
                callback: formatValue,
              },
            },
          },
          plugins: {
            legend: {
              position: "right",
              labels: {
                font: {
                  family: "Helvetica Neue",
                  size: 14,
                },
              },
            },
            tooltip: {
              callbacks: {
                label: (tooltipItems) => {
                  if (formatValue) {
                    tooltipItems.formattedValue = formatValue(
                      (tooltipItems.raw as { y: number }).y
                    );
                  }
                },
              },
              titleFont: {
                family: "Helvetica Neue",
                size: 14,
              },
              bodyFont: {
                family: "Helvetica Neue",
                size: 14,
              },
            },
          },
        }}
      />
    </LinePlotContainer>
  );
};

export default LinePlot;
