import { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import styled from "styled-components";

const StyledHintIcon = styled.i`
  cursor: pointer;
  padding-left: 5px;
  font-size: 14px;
`;

const StyledTooltipContent = styled.span`
  text-align: left;
  display: inline-block;
`;

const HintIcon = ({
  className,
  children,
  placement,
}: {
  className?: string;
  children: ReactNode;
  placement?: Placement;
}) => {
  const tooltip = (
    <Tooltip style={{ position: "fixed" }}>
      <StyledTooltipContent>{children}</StyledTooltipContent>
    </Tooltip>
  );
  return (
    <OverlayTrigger overlay={tooltip} placement={placement}>
      <StyledHintIcon className={`${className} bi bi-question-circle`} />
    </OverlayTrigger>
  );
};

export default HintIcon;
