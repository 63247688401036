import { ReactElement, ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import styled from "styled-components";

const StyledTooltip = styled(Tooltip)`
  position: fixed;
  .tooltip-inner {
    width: fit-content;
    max-width: none !important;
  }
`;

const TooltipProvider = ({
  children,
  tooltip,
  placement,
}: {
  children: ReactElement<any | string | React.JSXElementConstructor<any>>;
  tooltip: ReactNode;
  placement?: Placement;
}) => {
  if (tooltip) {
    const overlay = <StyledTooltip>{tooltip}</StyledTooltip>;
    return (
      <OverlayTrigger overlay={overlay} placement={placement}>
        {children}
      </OverlayTrigger>
    );
  } else {
    return children;
  }
};

export default TooltipProvider;
