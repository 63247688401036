import { useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import styled from "styled-components";

import { CommonMomentSummary } from "../types";
import { TooltipProvider } from "@bagel-web/components";
import { FieldType } from "@bagel-web/components";
import useFilters from "../filter/useFilters";

const MomentNavContainer = styled.span``;

const fields = [
  {
    name: "moment",
    label: "Moment",
    type: FieldType.String,
  },
];

const MomentNavigator = ({
  startOfStream,
  endOfStream,
  moments,
  detailMomentId,
  refetch,
  isRefetching,
}: {
  startOfStream: HTMLElement | null;
  endOfStream: HTMLElement | null;
  moments: CommonMomentSummary[];
  detailMomentId: number | null;
  refetch: () => void;
  isRefetching: boolean;
}) => {
  const ignoreScrollEventsTimer = useRef<null | number>(null);
  const batchScrollEventsTimer = useRef<null | number>(null);

  const [currentMomentIndex, setCurrentMomentIndex] = useState(0);
  const { filterCriteria, handleFilterChange } = useFilters(fields);

  const pauseScrollHandling = () => {
    if (ignoreScrollEventsTimer.current) {
      clearTimeout(ignoreScrollEventsTimer.current);
    }
    ignoreScrollEventsTimer.current = window.setTimeout(() => {
      ignoreScrollEventsTimer.current = null;
    }, 1000);
  };

  const scrollToTop = () => {
    pauseScrollHandling();
    startOfStream?.scrollIntoView();
    setCurrentMomentIndex(0);
  };

  const scrollToBottom = () => {
    pauseScrollHandling();
    endOfStream?.scrollIntoView();
    setCurrentMomentIndex(moments.length - 1);
  };

  const scrollToMoment = (momentId: number | undefined) => {
    pauseScrollHandling();
    window.document.getElementById(`moment-${momentId}`)?.scrollIntoView();
  };

  const handleScroll = useCallback(() => {
    if (ignoreScrollEventsTimer.current !== null) {
      return;
    }

    if (batchScrollEventsTimer.current !== null) {
      clearTimeout(batchScrollEventsTimer.current);
    }

    batchScrollEventsTimer.current = window.setTimeout(() => {
      const containerElement = startOfStream?.parentElement;
      const momentElements =
        containerElement?.querySelectorAll(".moment-summary") || [];
      const containerTop = containerElement?.getBoundingClientRect()?.top || 0;
      const containerBottom =
        containerElement?.getBoundingClientRect()?.bottom || 0;

      const firstVisibleMomentElement =
        Array.from(momentElements).find((element) => {
          const { top: momentTop } = element.getBoundingClientRect();
          return containerTop <= momentTop && momentTop <= containerBottom;
        }) ||
        Array.from(momentElements).findLast((element) => {
          const { top: momentTop } = element.getBoundingClientRect();
          return momentTop < containerTop;
        });

      const dataMomentId =
        firstVisibleMomentElement?.getAttribute("data-moment-id");
      if (dataMomentId) {
        const momentId = Number.parseInt(dataMomentId);
        setCurrentMomentIndex(
          moments.findIndex((moment) => moment.id === momentId)
        );
      }
      batchScrollEventsTimer.current = null;
    }, 500);
  }, [ignoreScrollEventsTimer, moments, startOfStream, setCurrentMomentIndex]);

  useEffect(() => {
    // Register scroll listener
    startOfStream?.parentElement?.addEventListener("scroll", handleScroll);

    filterCriteria["moment"]?.value &&
      scrollToMoment(Number.parseInt(filterCriteria["moment"]?.value));

    return () => {
      // Deregister scroll listener
      startOfStream?.parentElement?.removeEventListener("scroll", handleScroll);
    };
  }, [startOfStream]);

  useEffect(() => {
    if (detailMomentId) {
      handleFilterChange("moment", `${detailMomentId}`);
    }
  }, [detailMomentId]);

  const showPrevMoment = () => {
    if (currentMomentIndex <= 0 || !moments[currentMomentIndex - 1]) return;

    setCurrentMomentIndex(currentMomentIndex - 1);
    scrollToMoment(moments[currentMomentIndex - 1]?.id);
  };

  const showNextMoment = () => {
    if (
      currentMomentIndex >= moments.length - 1 ||
      !moments[currentMomentIndex + 1]
    )
      return;

    setCurrentMomentIndex(currentMomentIndex + 1);
    scrollToMoment(moments[currentMomentIndex + 1]?.id);
  };

  return (
    <MomentNavContainer style={{ textAlign: "right" }}>
      <ButtonGroup>
        <TooltipProvider tooltip="Earliest">
          <Button
            className="bi-chevron-bar-up"
            variant="secondary"
            onClick={() => scrollToTop()}
          />
        </TooltipProvider>
        <TooltipProvider tooltip="Previous">
          <Button
            className="bi-chevron-up"
            variant="secondary"
            onClick={() => showPrevMoment()}
          />
        </TooltipProvider>
        <Button variant="secondary" disabled>
          {`Moment ${moments[currentMomentIndex]?.id}`}
        </Button>
        <TooltipProvider tooltip="Next">
          <Button
            className="bi-chevron-down"
            variant="secondary"
            onClick={() => showNextMoment()}
          />
        </TooltipProvider>
        <TooltipProvider tooltip="Latest">
          <Button
            className="bi-chevron-bar-down"
            variant="secondary"
            onClick={() => scrollToBottom()}
          />
        </TooltipProvider>
        {!isRefetching && (
          <TooltipProvider tooltip="Refresh">
            <Button
              className="bi-arrow-clockwise"
              variant="secondary"
              onClick={() => refetch()}
            />
          </TooltipProvider>
        )}
        {isRefetching && (
          <Button variant="secondary" disabled>
            <Spinner size="sm" />
          </Button>
        )}
      </ButtonGroup>
    </MomentNavContainer>
  );
};

export default MomentNavigator;
