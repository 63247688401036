import { ReactNode } from "react";

import styled from "styled-components";

import { ColorAA } from "../constants";
import TooltipProvider from "../TooltipProvider";

const BreakdownBarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1px;
`;

const BreakdownSectionContainer = styled.div<{ $size: number; color?: string }>`
  flex: ${(props) => props.$size};
  min-width: 5px;
  text-wrap: nowrap;
  &:last-child {
    text-align: right;
  }
`;

const BarSegmentLabel = styled.div`
  color: ${ColorAA.grey};
`;

const BarSegmentWrapper = styled.div`
  height: 30px;
  display: block;
  position: relative;
`;

const BarSegment = styled.div<{
  $stripePattern?: boolean;
  $color?: string;
  $disabled?: boolean;
}>`
  border-radius: 3px;
  width: 100%;
  height: 80%;
  transition: height 0.2s;
  position: absolute;
  bottom: 0;

  ${({ $disabled }) =>
    $disabled
      ? ``
      : `&:hover {
    height: 100%;
  }`}

  ${({ $stripePattern, $color }) =>
    $stripePattern
      ? `
      border: ${$color} solid 2px;
    `
      : `
      background-color: ${$color};
    `}
`;

type Segment = {
  size: number;
  color?: string;
  stripePattern?: boolean;
  label?: string;
  tooltip?: ReactNode;
};

const DEFAULT_COLORS = [
  ColorAA.violet,
  ColorAA.turquoise,
  ColorAA.yellow,
  ColorAA.blue,
];

const EmptySection = () => {
  return (
    <BreakdownSectionContainer $size={100}>
      <BarSegmentWrapper>
        <BarSegment $disabled={true} $color={ColorAA.lightGrey} />
      </BarSegmentWrapper>
    </BreakdownSectionContainer>
  );
};

const BreakdownSection = ({
  segment,
  defaultColor,
}: {
  segment: Segment;
  defaultColor?: string;
}) => {
  return (
    <BreakdownSectionContainer $size={segment.size}>
      <TooltipProvider tooltip={segment.tooltip}>
        <BarSegmentWrapper>
          <BarSegment
            $stripePattern={segment.stripePattern}
            $color={segment.color || defaultColor}
          ></BarSegment>
        </BarSegmentWrapper>
      </TooltipProvider>
      <BarSegmentLabel>{segment.label}</BarSegmentLabel>
    </BreakdownSectionContainer>
  );
};

const BreakdownBar = ({ segments }: { segments: Array<Segment> }) => {
  return (
    <BreakdownBarContainer>
      {segments.length === 0 && <EmptySection />}
      {segments.map((segment, index) => (
        <BreakdownSection
          key={`segment-${index}`}
          segment={segment}
          defaultColor={DEFAULT_COLORS[index % DEFAULT_COLORS.length]}
        />
      ))}
    </BreakdownBarContainer>
  );
};

export default Object.assign(BreakdownBar, {
  DefaultColors: DEFAULT_COLORS,
});
