import { FC, ReactNode } from "react";

import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Placement } from "react-bootstrap/esm/types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const StyledPopover = styled(Popover)`
  position: fixed;
  max-width: fit-content;
`;

const OverlayTarget = styled.div`
  width: fit-content;
`;

const InspectorContainer = styled.div`
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: fit-content;
`;

const StyledSpinner = styled(Spinner)``;

export const Inspector = <P extends object>({
  children,
  onEnter,
  placement = "right",
  showLoading = true,
  viewProps,
  View,
}: {
  children?: ReactNode;
  onEnter?: () => void;
  placement?: Placement;
  showLoading?: boolean;
  viewProps: P;
  View: FC<P>;
}) => {
  const popover = (
    <StyledPopover>
      <InspectorContainer>
        {showLoading ? <StyledSpinner /> : viewProps && <View {...viewProps} />}
      </InspectorContainer>
    </StyledPopover>
  );

  return (
    <OverlayTrigger onEnter={onEnter} placement={placement} overlay={popover}>
      <OverlayTarget>{children}</OverlayTarget>
    </OverlayTrigger>
  );
};
