import { ColorAA } from "../constants";
import BreakdownBar from "./BreakdownBar";

const getUsedLabel = (count: number) => {
  return count === 1
    ? `${Intl.NumberFormat().format(count)} request used`
    : `${Intl.NumberFormat().format(count)} requests used`;
};

const getRemainingLabel = (count: number) => {
  return count === 1
    ? `${Intl.NumberFormat().format(count)} request remaining`
    : `${Intl.NumberFormat().format(count)} requests remaining`;
};

const getLimitLabel = (count: number) => {
  return count === 1
    ? `${Intl.NumberFormat().format(count)} request max`
    : `${Intl.NumberFormat().format(count)} requests max`;
};

const getExcessLabel = (count: number) => {
  return count === 1
    ? `${Intl.NumberFormat().format(count)} request above limit`
    : `${Intl.NumberFormat().format(count)} requests above limit`;
};

export const RequestUsageBar = ({
  used,
  max,
  color,
}: {
  used: number;
  max: number;
  color?: string;
}) => {
  const remaining = max - used;

  return remaining >= 0 ? (
    <BreakdownBar
      segments={[
        {
          size: used,
          tooltip: getUsedLabel(used),
          color: color,
        },
        {
          size: remaining,
          tooltip: getRemainingLabel(remaining),
          color: ColorAA.lightGrey,
        },
      ]}
    />
  ) : (
    <BreakdownBar
      segments={[
        {
          size: max,
          tooltip: getLimitLabel(max),
          color: ColorAA.pink,
        },
        {
          size: used - max,
          tooltip: getExcessLabel(used - max),
          color: ColorAA.pink,
          stripePattern: true,
        },
      ]}
    />
  );
};
