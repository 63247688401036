import { ReactNode } from "react";

import styled from "styled-components";

const FieldContent = styled.div`
  margin: 0px 20px 5px 0px;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FieldLabel = styled(FieldContent)`
  font-weight: bold;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(max-content, 100px) minmax(max-content, 350px)
  );
`;

const HeaderDetailField = ({
  children,
  label,
}: {
  children?: ReactNode;
  label: string;
}) => {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <FieldContent>{children}</FieldContent>
    </>
  );
};

const HeaderDetail = ({ children }: { children?: ReactNode }) => {
  return <HeaderContainer>{children}</HeaderContainer>;
};

export default Object.assign(HeaderDetail, {
  Field: HeaderDetailField,
});
