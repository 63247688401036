import { useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import {
  GridForm,
  GridFormItemWrapper,
  GridFormButtonContainer,
  FieldType,
} from "@bagel-web/components";
import { Field, Fields, FilterCriteria } from "./types";

const FilterForm = ({
  fields,
  criteria,
  onSetFilters,
}: {
  fields: Fields;
  criteria: FilterCriteria<string>;
  onSetFilters: (criteria: FilterCriteria<string>) => void;
}) => {
  const [localCriteria, setLocalCriteria] = useState<FilterCriteria<string>>(
    () => {
      const initCriteria = { ...criteria };
      return initCriteria;
    }
  );

  const handleFilterChange = useCallback(
    (field: Field) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCriteria = { ...localCriteria };
      if (e.target.value && e.target.value !== "") {
        updatedCriteria[field.name] = { value: e.target.value };
      } else {
        delete updatedCriteria[field.name];
      }
      setLocalCriteria(updatedCriteria);
    },
    [localCriteria]
  );

  const handleApplyFilter = useCallback(() => {
    onSetFilters(localCriteria);
  }, [onSetFilters, localCriteria]);

  const getFilterField = useCallback(
    (field: Field) => {
      if (field.Component) {
        return (
          <field.Component
            key={field.name}
            value={localCriteria[field.name]?.value || ""}
            onChange={handleFilterChange(field)}
          />
        );
      } else if (field.type === FieldType.DateTime) {
        return (
          <Form.Control
            key={field.name}
            type="datetime-local"
            value={localCriteria[field.name]?.value || ""}
            onChange={handleFilterChange(field)}
            step="1"
          />
        );
      } else {
        return (
          <Form.Control
            key={field.name}
            type="search"
            value={localCriteria[field.name]?.value || ""}
            onChange={handleFilterChange(field)}
          />
        );
      }
    },
    [localCriteria, handleFilterChange]
  );

  return (
    <>
      <GridForm>
        {fields.map((field) => (
          <GridFormItemWrapper colSpan={field.colSpan} key={field.name}>
            <Form.Label column>{field.label}</Form.Label>
            {getFilterField(field)}
          </GridFormItemWrapper>
        ))}
        <GridFormButtonContainer>
          <Button onClick={handleApplyFilter}>Apply</Button>
        </GridFormButtonContainer>
      </GridForm>
    </>
  );
};

export default FilterForm;
