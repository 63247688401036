import { AttributesView, toAttributes } from "@bagel-web/components";
import styled from "styled-components";

import { formatTimestamp } from "../util";
import { CommonSessionDetails } from "../types";

const SessionName = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
`;

const MetadataLabel = styled.div`
  margin: 4px 0px;
  font-weight: bold;
`;

const SessionDetails = ({
  session,
}: {
  session: CommonSessionDetails | null;
}) => {
  return (
    <div>
      <SessionName>{<code>{session?.id}</code>}</SessionName>
      <>
        <AttributesView
          attributes={[
            {
              name: "created",
              label: "Creation time",
              value: formatTimestamp(session?.created),
              showMonoValue: false,
            },
            {
              name: "expires_at",
              label: "Expiration time",
              value: formatTimestamp(session?.expires_at),
              showMonoValue: false,
            },
          ]}
        />
        {Object.entries(session?.metadata || {}).length > 0 && (
          <>
            <MetadataLabel>Metadata</MetadataLabel>
            <AttributesView
              attributes={[...(toAttributes(session?.metadata) || [])]}
            />
          </>
        )}
      </>
    </div>
  );
};

export default SessionDetails;
