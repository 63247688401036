import { useState, useEffect, useCallback } from "react";
import {
  usePathname,
  useSearchParams,
  useRouter,
  ReadonlyURLSearchParams,
} from "next/navigation";
import { Field, Fields, FilterCriteria } from "@bagel-web/components";
import { useLocalStorage } from "../util/localStorage";

const getCriteriaFromSearchParams = (
  fields: Fields,
  searchParams: ReadonlyURLSearchParams
) => {
  const criteria: FilterCriteria<string> = {};
  fields.forEach((field) => {
    const value = searchParams.get(field.name);
    if (value) {
      criteria[field.name] = { value };
    }
  });
  return criteria;
};

const getQualifiedPersistencyKey = (persistenceKey: string) => {
  return `filters_${persistenceKey}`;
};

const useFilters = (
  fields: Array<Field>,
  persistenceKey?: string
): {
  filterCriteria: FilterCriteria<string>;
  handleClearFilter: (fieldName: string) => void;
  handleSetFilters: (updatedCriteria: FilterCriteria<string>) => void;
  handleFilterChange: (fieldName: string, value: string) => void;
} => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathName = usePathname();

  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria<string>>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleClearFilter = useCallback(
    (fieldName: string) => {
      const updatedCriteria = { ...filterCriteria };
      delete updatedCriteria[fieldName];
      setFilterCriteria(updatedCriteria);
    },
    [filterCriteria]
  );

  const handleSetFilters = useCallback(
    (updatedCriteria: FilterCriteria<string>) => {
      setFilterCriteria(updatedCriteria);
    },
    []
  );

  const handleFilterChange = useCallback(
    (fieldName: string, value: string) => {
      const updatedCriteria = { ...filterCriteria };
      updatedCriteria[fieldName] = { value };
      setFilterCriteria(updatedCriteria);
    },
    [filterCriteria]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const params = new URLSearchParams();
    fields.forEach((field) => {
      const value = filterCriteria[field.name]?.value;
      if (value) {
        params.set(field.name, value);
      }
    });
    router.push(pathName + "?" + params.toString());
    persistenceKey &&
      useLocalStorage<FilterCriteria<string>>().setItem(
        getQualifiedPersistencyKey(persistenceKey),
        filterCriteria
      );
  }, [isLoading, fields, filterCriteria, router, pathName]);

  useEffect(() => {
    const urlCriteria = getCriteriaFromSearchParams(fields, searchParams);
    const storedCriteria = persistenceKey
      ? useLocalStorage<FilterCriteria<string>>().getItem(
          getQualifiedPersistencyKey(persistenceKey)
        )
      : {};

    if (Object.keys(urlCriteria).length > 0) {
      setFilterCriteria(urlCriteria);
    } else if (storedCriteria && Object.keys(storedCriteria).length > 0) {
      setFilterCriteria(storedCriteria);
    }
    setIsLoading(false);
  }, [fields, searchParams.toString()]);

  return {
    filterCriteria,
    handleClearFilter,
    handleSetFilters,
    handleFilterChange,
  };
};

export default useFilters;
