import { ColorAA } from "@bagel-web/components";

const modelColorMap: { [key: string]: string } = {};
let nextColorIndex = 0;

export type ModelColorMap = {
  getColor: (modelName: string) => string;
};

const DEFAULT_COLORS = [
  ColorAA.violet,
  ColorAA.turquoise,
  ColorAA.yellow,
  ColorAA.blue,
  ColorAA.pink,
];

const useModelColorMap = (): ModelColorMap => {
  const getColor = (modelName: string): string => {
    let modelColor = modelColorMap[modelName];
    if (!modelColor) {
      modelColor = DEFAULT_COLORS[nextColorIndex];
      modelColorMap[modelName] = modelColor!;
      nextColorIndex = (nextColorIndex + 1) % DEFAULT_COLORS.length;
    }

    return modelColor!;
  };

  return { getColor };
};

export default useModelColorMap;
