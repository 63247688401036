export type UserContext = {
  userId: string | null;
  organization: string | null;
};

let _userContext: UserContext | null;

export const initUserContext = (
  userId: string | null,
  organization: string | null
) => {
  _userContext = { userId, organization };
};

export const getUserContext = (): UserContext => {
  if (!_userContext) {
    throw new Error("Uninitialized user context");
  }
  return _userContext;
};
