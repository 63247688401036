import { useState } from "react";
import styled from "styled-components";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import IconButton from "./IconButton";

const SecretValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SecretValue = ({
  redactedValue,
  fetchSecretValue,
}: {
  redactedValue: string;
  fetchSecretValue: () => Promise<string>;
}) => {
  const [revealedValue, setRevealedValue] = useState<string | null>(null);
  const [showRevealedValue, setShowRevealedValue] = useState<boolean>(false);

  const getSecretValue = async () => {
    if (revealedValue) {
      return revealedValue;
    } else {
      const secretValue = await fetchSecretValue();
      setRevealedValue(secretValue);
      return secretValue;
    }
  };

  const revealSecretKey = async () => {
    await getSecretValue();
    setShowRevealedValue(true);
  };

  const copySecretKey = async () => {
    const secretValue = await getSecretValue();
    navigator.clipboard.writeText(secretValue);
  };

  const copyTooltip = <Tooltip style={{ position: "fixed" }}>Copied</Tooltip>;

  const copyKeyButton = (
    <OverlayTrigger overlay={copyTooltip} trigger="click" rootClose>
      <IconButton className={"bi-copy"} onClick={copySecretKey} />
    </OverlayTrigger>
  );

  return (
    <SecretValueContainer>
      {showRevealedValue ? (
        <code>{revealedValue}</code>
      ) : (
        <>
          <code>{redactedValue}</code>
          <IconButton className={"bi-eye-fill"} onClick={revealSecretKey} />
        </>
      )}
      {copyKeyButton}
    </SecretValueContainer>
  );
};
