import { usePathname } from "next/navigation";
import { NavItem } from "@bagel-web/components";

export const getNavConfig = (): NavItem[] => {
  return [
    {
      href: "/projects",
      iconClassName: "bi-folder-fill",
      label: "Projects",
    },
    {
      href: "/sessions",
      iconClassName: "bi-clock-history",
      label: "Sessions",
    },
    {
      href: "/api-requests",
      iconClassName: "bi-arrow-left-right",
      label: "API requests",
    },
    {
      href: "/reports",
      iconClassName: "bi-bar-chart-fill",
      label: "Usage",
    },
    {
      href: "/users",
      iconClassName: "bi-people-fill",
      label: "Users",
    },
    {
      href: "/api-keys",
      iconClassName: "bi-braces",
      label: "API keys",
    },
  ];
};

export const getIsActive = (navItem: NavItem) => {
  const pathname = usePathname();
  return pathname?.startsWith(navItem.href);
};
