import { getUserContext } from "./userContext";

export type Storage<T> = {
  setItem: (persistenceKey: string, value: T | null) => void;
  getItem: (persistenceKey: string) => T | null;
};

const getStorageKey = (persistenceKey: string) => {
  const userContext = getUserContext();
  return userContext.organization
    ? `${userContext.organization}_${userContext.userId}_${persistenceKey}`
    : `${userContext.userId}_${persistenceKey}`;
};

export const useLocalStorage = <T>(): Storage<T> => {
  return {
    setItem: (persistenceKey: string, value: T | null) => {
      window.localStorage.setItem(
        getStorageKey(persistenceKey),
        JSON.stringify(value)
      );
    },
    getItem: (persistenceKey: string) => {
      const storedValue = window.localStorage.getItem(
        getStorageKey(persistenceKey)
      );
      return storedValue ? (JSON.parse(storedValue) as T) : null;
    },
  };
};
