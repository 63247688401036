import { ReactNode } from "react";
import styled from "styled-components";
import { LinkComponentType } from "./Sidebar";

const BackLinkContainer = styled.div`
  margin-bottom: 5px;
`;

const BackLink = ({
  children,
  LinkComponent,
  href,
}: {
  children: ReactNode;
  LinkComponent: LinkComponentType;
  href: string;
}) => {
  return (
    <BackLinkContainer>
      <LinkComponent href={href}>
        <i className="bi-chevron-left" />
        {children}
      </LinkComponent>
    </BackLinkContainer>
  );
};

export default BackLink;
