import styled from "styled-components";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { Fields, FilterCriteria } from "./types";
import FilterChip from "./FilterChip";
import FilterForm from "./FilterForm";
import {
  Toolbar,
  FixedToolbarItem,
  FlexToolbarItem,
} from "@bagel-web/components";

const FilterChipContainer = styled(FlexToolbarItem)`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
`;

const StyledPopover = styled(Popover)`
  max-width: fit-content;
`;

const FilterBar = ({
  fields,
  criteria,
  onClear,
  onSetFilters,
}: {
  fields: Fields;
  criteria: FilterCriteria<string>;
  onClear: (fieldName: string) => void;
  onSetFilters: (criteria: FilterCriteria<string>) => void;
}) => {
  const popover = (
    <StyledPopover>
      <Popover.Body>
        <FilterForm
          fields={fields}
          criteria={criteria}
          onSetFilters={onSetFilters}
        />
      </Popover.Body>
    </StyledPopover>
  );

  return (
    <Toolbar>
      <FilterChipContainer>
        {fields.map((field) =>
          criteria[field.name] ? (
            <FilterChip
              key={field.name}
              onClick={() => onClear(field.name)}
              field={field}
              value={criteria[field.name]?.value}
            />
          ) : null
        )}
      </FilterChipContainer>
      <FixedToolbarItem>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="left"
          overlay={popover}
        >
          <Button>
            <span>
              <i className="bi-filter" /> Filter
            </span>
          </Button>
        </OverlayTrigger>
      </FixedToolbarItem>
    </Toolbar>
  );
};

export default FilterBar;
