import styled, { css } from "styled-components";

export const GridForm = styled.form<{ $width?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: ${({ $width }) => $width || "fit-content"};
  gap: 10px;
`;

export const GridFormItemWrapper = styled.div<{ colSpan?: number }>`
  ${(props) => {
    if (props.colSpan) {
      return css`
        grid-column-end: span ${props.colSpan};
      `;
    }
  }}
`;

export const GridFormFlexItemWrapper = styled(GridFormItemWrapper)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;

  label {
    min-width: fit-content;
    width: 140px;
  }
`;

export const GridFormButtonContainer = styled.div`
  grid-column: 1/-1;
  display: flex;
  flex-flow: row-reverse nowrap;
  gap: 10px;
`;
