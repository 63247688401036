import { ReactNode } from "react";

import { Card as BootstrapCard } from "react-bootstrap";
import styled from "styled-components";

export const DEFAULT_UNIT_WIDTH = 400;
export const DEFAULT_UNIT_HEIGHT = 100;
export const DEFAULT_GRID_GAP = 20;
export const DEFAULT_COL_SPAN = 1;
export const DEFAULT_ROW_SPAN = 4;

const CardGridContainer = styled.div`
  container-name: aa-card-grid-container;
  container-type: inline-size;
`;

const StyledCardGrid = styled.div<{
  $unitHeight: number;
  $unitWidth: number;
}>`
  display: grid;
  grid-auto-flow: row dense;
  --aa-card-grid-column-count: 1;
  --aa-card-grid-column-width: 100%;
  @container aa-card-grid-container (width > ${({ $unitWidth }) =>
    $unitWidth * 1}px) {
    --aa-card-grid-column-count: 2;
    --aa-card-grid-column-width: calc(50% - ${(1 / 2) * DEFAULT_GRID_GAP}px);
  }
  @container aa-card-grid-container (width > ${({ $unitWidth }) =>
    $unitWidth * 2}px) {
    --aa-card-grid-column-count: 3;
    --aa-card-grid-column-width: calc(33% - ${(2 / 3) * DEFAULT_GRID_GAP}px);
  }
  @container aa-card-grid-container (width > ${({ $unitWidth }) =>
    $unitWidth * 3}px) {
    --aa-card-grid-column-count: 4;
    --aa-card-grid-column-width: calc(
      calc(25% - ${(3 / 4) * DEFAULT_GRID_GAP}px)
    );
  }

  grid-template-columns: repeat(
    var(--aa-card-grid-column-count),
    var(--aa-card-grid-column-width)
  );
  grid-template-rows: min-content;
  max-width: 2000px;
  width: 100%;
  gap: ${DEFAULT_GRID_GAP}px;
  margin-bottom: 20px;
`;

const CardContainer = styled.div<{ $colSpan: number; $rowSpan: number }>`
  grid-column: span
    min(${({ $colSpan }) => $colSpan}, var(--aa-card-grid-column-count));
  grid-row: span ${({ $rowSpan }) => $rowSpan};
`;

const StyledBootstrapCard = styled(BootstrapCard)`
  height: 100%;
`;

export const CardGrid = ({
  unitWidth = DEFAULT_UNIT_WIDTH,
  unitHeight = DEFAULT_UNIT_HEIGHT,
  children,
}: {
  unitWidth?: number;
  unitHeight?: number;
  children?: ReactNode;
}) => {
  return (
    <CardGridContainer>
      <StyledCardGrid $unitWidth={unitWidth} $unitHeight={unitHeight}>
        {children}
      </StyledCardGrid>
    </CardGridContainer>
  );
};

export const Card = ({
  id,
  title,
  children,
  colSpan = DEFAULT_COL_SPAN,
  rowSpan = DEFAULT_ROW_SPAN,
}: {
  id: string;
  title?: ReactNode;
  children?: ReactNode;
  colSpan?: number;
  rowSpan?: number;
}) => {
  return (
    <CardContainer key={id} $colSpan={colSpan} $rowSpan={rowSpan}>
      <StyledBootstrapCard>
        <BootstrapCard.Body>
          {title && <BootstrapCard.Title>{title}</BootstrapCard.Title>}
          {children}
        </BootstrapCard.Body>
      </StyledBootstrapCard>
    </CardContainer>
  );
};
